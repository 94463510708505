import { Dropdown, Layout, MenuProps } from 'antd';
import React, { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import ScaledImage from './components/ScaledImage';
import CHImage from './images/CH.png';
import ENImage from './images/EN.png';
import iconDown from './images/icon-down.png';
import logo from './images/logo@3x.png';
import './Layout.scss';
import { LocaleContext, LocaleDispatchContext, LocaleFileContext } from './locale';

const { Header } = Layout;

const App: React.FC = () => {
  const locale = useContext(LocaleContext);
  const dispatchLocale = useContext(LocaleDispatchContext);
  const localeFile = useContext(LocaleFileContext);
  const dropDownMenu: MenuProps['items'] = [
    {
      key: 'doc',
      label: (
        <Link to={`support/${locale}_index`}>
          <div className="eulix-dropdown-menu-item">{localeFile['layout.doc']}</div>
        </Link>
      ),
    },
    {
      key: 'contact',
      label: (
        <Link to="contact">
          <div className="eulix-dropdown-menu-item">{localeFile['layout.contact']}</div>
        </Link>
      ),
    },
  ];

  return (
    <Layout>
      <ScaledImage defaultHeight={80} maxRatio={1}>
        <Header className="eulix-header">
          <Link to="/">
            <img className="demo-logo" src={logo} />
          </Link>
          <div className="eulix-header-menu">
            <Link to="products">
              <div className="eulix-menu-item">{localeFile['layout.products']}</div>
            </Link>
            <a href="https://landscape.eulixos.com" target="_blank" rel="noreferrer">
              <div className="eulix-menu-item">{localeFile['layout.ecology']}</div>
            </a>
            <Dropdown menu={{ items: dropDownMenu }} overlayClassName="eulix-dropdown">
              <div className="eulix-menu-item">
                <div>{localeFile['layout.support']}</div>
                <img style={{ width: 20, height: 20, marginLeft: 4 }} src={iconDown} />
              </div>
            </Dropdown>
            <Link to="download">
              <div className="eulix-menu-item">{localeFile['layout.download']}</div>
            </Link>
          </div>
          <div className="eulix-header-right" onClick={() => dispatchLocale({ type: 'change', payload: locale === 'en-US' ? 'zh-Hans' : 'en-US' })}>
            <img className="locale" src={locale === 'en-US' ? ENImage : CHImage }/>
            <div className="locale-text">{locale === 'en-US' ? 'EN' : '中'}</div>
          </div>
        </Header>
      </ScaledImage>
      <div className="content">
        <Outlet />
      </div>
      <ScaledImage defaultHeight={163} maxRatio={1}>
      <div className="eulix-footer">
        <div className="footer-nav-row">
          <Link to="/">
            <div className="footer-nav-item" style={{ marginRight: 11, marginLeft: 8 }}>{localeFile['layout.home']}</div>
          </Link>
          <Link to="products">
            <div className="footer-nav-item" style={{ marginRight: 15 }}>{localeFile['layout.products']}</div>
          </Link>
          <a href="https://landscape.eulixos.com" target="_blank" rel="noreferrer">
            <div className="footer-nav-item" style={{ marginRight: 15 }}>{localeFile['layout.ecology']}</div>
          </a>
          <Dropdown menu={{ items: dropDownMenu }} overlayClassName="eulix-dropdown">
            <div className="footer-nav-item" style={{ marginRight: 25 }}>
              <div>{localeFile['layout.support']}</div>
              <img style={{ width: 20, height: 20, marginLeft: 4 }} src={iconDown} />
            </div>
          </Dropdown>
          <Link to="support">
          </Link>
          <Link to="download">
            <div className="footer-nav-item" style={{ marginRight: 12 }}>{localeFile['layout.download']}</div>
          </Link>
        </div>
        <div className="copyright">京ICP备05046678号-16&nbsp;•&nbsp;Copyright© {localeFile['layout.copyright']}</div>
      </div>
      </ScaledImage>
    </Layout>
  );
};

export default App;
