export default {
  'layout.home': 'Home',
  'layout.products': 'Products',
  'layout.support': 'Support',
  'layout.contact': 'Contact US',
  'layout.ecology': 'Ecology',
  'layout.doc': 'EulixOS Documentation',
  'layout.download': 'Download',
  'layout.copyright': 'Institute of Software, Chinese Academy of Sciences',
  'home.block-1-title-row-1': 'Intelligent',
  'home.block-1-title-row-2': 'Computing Future',
  'home.block-1-description': 'EulixOS is a RISC-V operating system based on openEuler, compatible with major RISC-V platforms. Leveraging an open-source software ecosystem and customized design, we build a high-performance Linux distribution for the intelligent computing era.',
  'home.block-2-description': 'EulixOS specializes in porting and building Linux kernel and open-source operating system distributions based on RISC-V architecture, and promoting the open-source software ecosystem for data centers on RISC-V.',
  'home.block-2-col-title-1': 'Open Source Software',
  'home.block-2-col-title-2': 'Data Center',
  'home.block-2-col-title-3': 'Intelligent Computing',
  'home.block-3-title': 'RISC-V Hardware',
  'home.block-4-title': 'Empowering AI and the Cloud',
  'home.block-4-description': 'Support for more RISC-V native AI, data, and cloud computing packages, with a powerful software ecosystem to effortlessly meet your business deployment needs in the intelligent era.',
  'home.block-4-ai-title': 'AI',
  'home.block-4-ai-1': '1. EulixOS delivers optimized RISC-V V instruction extensions for leading AI frameworks and computational libraries, enhancing your computing operations.',
  'home.block-4-ai-2': '2. EulixOS boasts a rich vendor ecosystem, enabling quickly access to key hardware features through vendor toolchains.',
  'home.block-4-bigdata-title': 'Big Data',
  'home.block-4-bigdata-1': '1. EulixOS now supports various databases and big data frameworks, building a powerful big data ecosystem.',
  'home.block-4-bigdata-2': '2. Provides the optimized Java libraries boost Java application performance, data security, and fault tolerance.',
  'home.block-4-cloudcomputing-title': 'Cloud Computing',
  'home.block-4-cloudcomputing-1': '1. Integrates major open-source cloud service software to establish a comprehensive RISC-V cloud computing ecosystem.',
  'home.block-4-cloudcomputing-2': '2. RISC-V H instruction extensions are now part of the EulixOS software ecosystem, enhancing cloud virtualization performance.',
  'home.block-4-cloudcomputing-3': '3. EulixOS introduces Istio on RISC-V, enabling you to effortlessly build microservices applications.',
  'home.block-5-title': 'Partners',
  'home.block-5-subtitle-1': 'University',
  'home.block-5-subtitle-2': 'Company',
  'products.block-1-description': 'EulixOS specializes in porting and building Linux kernel and open-source operating system distributions based on RISC-V architecture, and promoting the open-source software ecosystem for data centers on RISC-V.',
  'products.block-2-title': 'Data Center',
  'products.block-2-description-1': 'Supports deployment on clusters composed of Dual SOPHGO SG2042 @2.2GHz with 64 cores',
  'products.block-2-description-2': 'Offers 250+ RISC-V native software packages for data centers domain.',
  'products.block-3-title': 'Seamless Compatibility with openEuler',
  'products.block-3-description-1': 'EulixOS is a derivative distribution of openEuler, help you seamless migrate from openEuler.',
  'products.block-3-description-2': 'EulixOS 3.0 maintains full compatibility with openEuler 24.03 LTS in terms of toolchain and development library versions, as well as ABI.',
  'products.block-4-title': 'Maturity continues to evolve',
  'products.block-4-description': 'EulixOS 3.0 offers the key software maturity tracking platform for data center domain, enabling iterative comparisons with software on the openEuler 24.03 LTS ARM platform and providing software maturity metrics.',
  'products.block-5-title': 'AI',
  'products.block-5-description-1': 'EulixOS has undergone deep optimization for the RISC-V architecture in the field of AI computing, providing comprehensive integration and adaptation of several mainstream open-source machine learning frameworks and computational libraries, including PyTorch, Apache Mahout, Scikit-Learn, Apache Spark Mllib, MXNet, Caffe, MNN, FFmpeg, OpenBLAS, OpenCV, CNTK, and ONNX. It has become the most comprehensive operating system to date in supporting AI frameworks and computational libraries. This groundbreaking innovation significantly enhances the capability and practicality of RISC-V architecture for AI applications in data centers.',
  'products.block-5-description-2': 'EulixOS fully capitalizes on the advantages of the RISC-V architecture by performing deep optimizations of AI frameworks and libraries using RVV (RISC-V Vector Extension). It is the first to integrate RVV features into the PyTorch framework, markedly improving operational efficiency and computational performance on EulixOS. These optimizations greatly accelerate AI task processing, providing robust computational power for efficient AI model inference and training.',
  'products.block-5-description-3': 'EulixOS has fully integrated the Cambricon software ecosystem, successfully adapting the Cambricon Neuware SDK, Cambricon PyTorch framework, CATCH library, Transformers library, and various large model libraries. We have achieved efficient execution of large language models such as ChatGLM(1/2/3)-6B and MiniCPM-2B, as well as multimodal models like VisualGLM-6B. This significantly broadens the support for AI applications in data centers while providing accelerated processing across diverse scenarios, empowering the full deployment and large-scale application of AI solutions.',
  'products.block-6-title': 'Big Data',
  'products.block-6-description-1': 'Diversified big data ecosystem, incorporating databases such as Redis and OpenGauss, as well as big data frameworks like Hadoop and Spark.',
  'products.block-6-description-2': 'Hadoop, with the support of native libraries, integrates the efficient compression algorithms zstd and isa-l, enhancing data security and improving performance.',
  'products.block-7-title': 'Cloud Computing',
  'products.block-7-description-1': 'As an open-source instruction set architecture, RISC-V is increasingly gaining attention and application in the field of cloud computing. EulixOS\'s RISC-V cloud ecosystem integrates mainstream open-source cloud service software to create a complete and efficient cloud computing environment, driving the development of RISC-V in modern cloud services',
  'products.block-7-description-2': 'Comprehensive Open-Source Migration: All software has been migrated and built based on the latest open-source versions, ensuring the ecosystem\'s openness and cutting-edge nature.',
  'products.block-7-description-3': 'Televm (Stratovirt) Support: Televm, a lightweight virtualization solution based on Stratovirt, is the first software to implement H-extension virtualization on the RISC-V architecture. It significantly enhances virtualization performance and resource management efficiency, providing a solid foundation for efficient virtualization in cloud computing environments.',
  'products.block-7-description-4': 'Istio\'s First RISC-V Support: As a critical component in microservices architecture, the Istio service mesh is fully supported on the RISC-V platform for the first time, further promoting RISC-V\'s application in microservices architectures.',
  'products.block-7-description-5': 'Docker and Kubernetes Support: Core cloud-native technologies, Docker and Kubernetes, are fully supported on the RISC-V platform. This provides the foundation for containerized deployment and large-scale cluster management, allowing users to achieve efficient, flexible container management and automated orchestration on the RISC-V platform.',
  'products.block-7-description-6': 'iSulad Lightweight Container Runtime: As a lightweight container runtime, iSulad is particularly suitable for container management in resource-constrained environments, offering more solutions for RISC-V\'s lightweight computing environments.',
  'products.block-7-description-7': 'Prometheus and Grafana Monitoring Tools: Prometheus, a powerful monitoring and alerting system, combined with Grafana, provides comprehensive monitoring and visualization solutions for cloud computing environments on the RISC-V platform.',
  'products.block-7-description-8': 'Helm Deployment Management: Helm, a package manager for Kubernetes, enables the automated deployment and management of complex applications on the RISC-V platform, making cloud application maintenance simpler and more efficient.',
  'products.block-7-description-9': 'EulixOS\'s RISC-V cloud computing ecosystem, while fully supporting mainstream cloud computing components, continues to expand its software compatibility and virtualization capabilities. Notably, with the first support for Istio and the first H-extension virtualization compatibility through Televm, the RISC-V platform offers more choices and possibilities for developers and enterprises.',
  'contact.title': 'Contact Us',
  'contact.block-1-title': 'Report an issue',
  'contact.block-1-desc-1-front': 'Please compose an email to ',
  'contact.block-1-desc-1-back': ',',
  'contact.block-1-desc-2': 'including a detailed issue description and error logs.',
  'contact.block-2-title': 'Connect to our community and team',
  'contact.block-2-desc': 'We plan to support more contact methods to help you reach us.',
}
