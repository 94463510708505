import React, { useContext } from 'react';
import ScaledImage from './components/ScaledImage';
import MailImage from './images/contact/mail.png';
import UsersImage from './images/contact/users.png';
import './Contact.scss';
import { LocaleFileContext } from './locale';

const Contact: React.FC = () => {
  const localeFile = useContext(LocaleFileContext);

  return (
    <div className="contact-container">
      <ScaledImage defaultHeight={730}>
        <div className="contact-wrapper">
          <div className="contact-title">{localeFile['contact.title']}</div>
          <div className="contact-block">
            <div className="contact-sub-block">
              <img className="block-icon" src={MailImage} />
              <div className="block-title">{localeFile['contact.block-1-title']}</div>
              <div className="block-desc">
                <div className="block-desc-row">{localeFile['contact.block-1-desc-1-front']}<a href="mailto:eulixos@iscas.ac.cn">eulixos@iscas.ac.cn</a>{localeFile['contact.block-1-desc-1-back']}</div>
                <div className="block-desc-row">{localeFile['contact.block-1-desc-2']}</div>
              </div>
            </div>
            <div className="contact-sub-block">
              <img className="block-icon" src={UsersImage} />
              <div className="block-title">{localeFile['contact.block-2-title']}</div>
              <div className="block-desc">
                <div className="block-desc-row">{localeFile['contact.block-2-desc']}</div>
              </div>
            </div>
          </div>
        </div>
      </ScaledImage>
    </div>
  )
};

Contact.displayName = 'Contact';

export default Contact;
