import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Contact from "./Contact";
import Download from "./Download";
import Home from "./Home";
import './index.css';
import Layout from "./Layout";
import Products from "./Products";
import Root from "./Root";
import Support from "./Support";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'products',
        element: <Products />,
      },
      {
        path: 'download',
        element: <Download />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'support/:key',
        element: <Support />,
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Root>
      <RouterProvider router={router} />
    </Root>
  </React.StrictMode>
);
